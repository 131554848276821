import React from 'react'
import { Helmet } from 'react-helmet'
import { RUMMY, RUMMY_LEARN } from '../../components/internal-links'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Blogs from '../../components/Blogs/Blogs'
import RUMMY_LEARN_PAGES from '../../rummy-tags'
import { Breadcrumb } from '../../components/Breadcrumbs/Breadcrumbs'
import { generateBreadcrumbsSchema } from '../../lib/generate-schema'
import { LATEST_RC as RUMMY_DOWNLOAD_LINK } from '../../components/download-links'
import { rummyOneLinksRummyPage } from '../../components/one-links'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../../utils/constnst.utils'
import { Script } from 'gatsby'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Online Rummy Game',
    url: RUMMY,
  },
  {
    title: 'Rummy Blog',
    url: RUMMY_LEARN,
  },
]

const LearnRummy: React.FC = () => {
  return (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      apkLink={RUMMY_DOWNLOAD_LINK}
      showRummyForm
      rummyOneLink={rummyOneLinksRummyPage}
    >
      <Helmet>
        {process.env.GATSBY_STAGING === 'true' && (
          <meta name="robots" content="noindex" />
        )}
        <script>
          {`
            document.addEventListener('DOMContentLoaded', () => {
              /** init gtm after 1500 seconds - this could be adjusted */
              setTimeout(initGTM, 1500);
            });
            document.addEventListener('scroll', initGTMOnEvent);
            document.addEventListener('mousemove', initGTMOnEvent);
            document.addEventListener('touchstart', initGTMOnEvent);
            function initGTMOnEvent(event) {
              initGTM();
              event.currentTarget.removeEventListener(event.type, initGTMOnEvent); // remove the event listener that got triggered
            }
            function initGTM() {
              if (window.gtmDidInit) {
                return false;
              }
              window.gtmDidInit = true; // flag to ensure script does not get added to DOM more than once.
              const script = document.createElement('script');
              script.type = 'text/javascript';
              script.async = true;
              // ensure PageViews is always tracked (on script load)
              script.onload = () => {
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', 'AW-718511341');
              };
              script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-718511341';
              document.head.appendChild(script);
            }
          `}
        </script>
      </Helmet>
      <SEO
        title="Rummy Gameplay: Top websites and strategies to play Rummy | Mega"
        description="Best way to play rummy is tough to find, isn't it? Why worry when we have listed the best websites, strategies, and simple gameplay. Read now."
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
      />
      <Blogs
        blogType="rummy"
        tags={RUMMY_LEARN_PAGES}
        prefix={`${RUMMY}${process.env.GATSBY_CMS_LEARN_RUMMY_PREFIX}` || '/'}
        title={
          <>
            Learn Rummy - <span className="underline">Guide</span>
          </>
        }
      />
    </Layout>
  )
}

export default LearnRummy
